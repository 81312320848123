export const LogoSmall = require('./images/logo-small.png');
export const LogoMedium = require('./images/logo-medium.png');
export const LogoLarge = require('./images/logo-large.png');

export const BedImage = require('./images/bed-image.webp');

export const CurtainsImage = require('./images/curtains-pic.webp');

export const LandingImage = require('./images/Landing.webp');

export const GoogleReviewsImage = require('./images/google-reviews.png');

export const WhiteSheerImage = require('./images/Sheer.webp');
export const BlackoutFabricImage = require('./images/Blackout.webp');
export const BlackoutAndSheerImage = require('./images/Blackout-and-sheer.webp');
export const RollerBlindsImage = require('./images/Roller.webp');

export const AmericanPleatImage = require('./images/American-pleat.webp');
export const WaveStyleImage = require('./images/Wave.webp');
export const EyeletImage = require('./images/Eyelet.webp');
export const SatinImage = require('./images/Satin.webp');

export const LinenImage = require('./images/Linen.webp');
export const JaquardImage = require('./images/Jaquard.webp');

export const TieBacksImage = require('./images/Tie-backs.webp');
export const PelmetsImage = require('./images/Extra-4.webp');
export const MotorizedImage = require('./images/Extra-3.webp');

export const MovingSticksImage = require('./images/Extra-1.webp');

export const StringsImage = require('./images/Extra-2.webp');

export const RomanBlindsImage = require('./images/Roman.webp');

export const BlackoutRollerImage = require('./images/Blackout-2.webp');

export const TranslucentRollerImage = require('./images/Translucent.webp');

export const SunscreenRollerImage = require('./images/Sunscreen.webp');

export const SideChannelRollerImage = require('./images/Side-channels.webp');

export const CassetteRollerImage = require('./images/Cassette.webp');

export const AboutUsLandingImage = require('./images/curtains-pic.webp');

export const MichaelImage = require('./images/michael.webp');

export const MariaImage = require('./images/maria.webp');

export const WhatsAppLogo = require('./images/whatsapp-logo.png');

export const AboutUsImage = require('./images/about-us.png');