import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import GetEstimate from "../../components/get-estimate/GetEstimate";
import {useEffect} from "react";

const GetEstimatePage = () => {
    useEffect(() => {
        // Make Sure We Are At The Top Of The Page
        window.document.body.scrollTop = 0;
    }, []);

    return (
        <>
            <Navbar />

            <main>
                <GetEstimate />
            </main>

            <Footer />
        </>
    )
}

export default GetEstimatePage;