import Navbar from "../../components/navbar/Navbar";
import './home.scss';
import {Col, Collapse, Input, Row, Tabs} from "antd";
import {
    AmericanPleatImage,
    BlackoutAndSheerImage,
    BlackoutFabricImage,
    BlackoutRollerImage, CassetteRollerImage,
    EyeletImage,
    GoogleReviewsImage,
    JaquardImage,
    LandingImage,
    LinenImage,
    MotorizedImage,
    MovingSticksImage,
    PelmetsImage,
    RollerBlindsImage,
    RomanBlindsImage,
    SatinImage, SideChannelRollerImage,
    StringsImage, SunscreenRollerImage,
    TieBacksImage,
    TranslucentRollerImage,
    WaveStyleImage,
    WhiteSheerImage
} from "../../assets/assets";
import {CalculatorOutlined, CalendarOutlined, CreditCardOutlined, PlusOutlined, TruckOutlined} from "@ant-design/icons";
import Footer from "../../components/footer/Footer";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";

import { Navigation, Pagination } from 'swiper/modules';
import GetEstimate from "../../components/get-estimate/GetEstimate";
import {useLocation, useNavigate, useParams} from "react-router-dom";


const HomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const onBookVisit = () => {
        navigate('/book');
    }

    const onGetEstimate = () => {
        navigate('/estimate');
    }

    useEffect(() => {
        if(location.search) {
            const section = new URLSearchParams(location.search).get('section');
            if(section) {
                const sectionElement = document.getElementById(section);
                if(sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [location]);

    return (
        <>
            <Navbar/>

            <main className='custom-container'>
                <div className='primary-section'>
                    <Row gutter={40}>
                        <Col xs={24} sm={24} md={12}>
                            <div className='flex items-center h-full'>
                                <div className='main-content'>
                                    <h1>Curtains <span>&</span> Blinds,<br/>Fast <span>&</span> Hassle-Free</h1>
                                    <p className='mt-5'>
                                        Get your dream curtains installed within 4 days<br/> & choose to pay in
                                        installments or upfront
                                    </p>
                                    <div className='flex gap-3 mt-7 desktop-only'>
                                        <button className='primary-button large mt-5'>Get an Estimate</button>
                                        <button className='outlined-button large mt-5'>Book a Free Visit</button>
                                    </div>
                                    <div className='mobile-only'>
                                        <div className='flex gap-3 mt-2 mb-7 justify-center items-center'>
                                            <button onClick={onGetEstimate} className='primary-button large mt-5'>Get an Estimate</button>
                                            <button onClick={onBookVisit} className='outlined-button large mt-5'>Book a Free Visit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <div className='image-content w-full'>
                                <div className='image-container'>
                                    <img src={LandingImage} alt={'Curtains'}/>
                                    <div className='tag'>About Us</div>
                                </div>

                                <div className='review-content'>
                                   <div className='flex justify-center items-center'>
                                       <div className='flex flex-col items-center text-center'>
                                           <img style={{ marginTop: '-20px' }} width={200} src={GoogleReviewsImage} alt='Google Reviews'/>
                                           <p style={{ color: '#79393d'}}>
                                               Highly recommended for custom-made
                                               curtains tailored to my preferences:<br/>fast, on-
                                               time, affordable, and an easy process!
                                           </p>
                                       </div>
                                   </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='section custom-container mt-16'>
                    <h2 className='text-center'>How it Works</h2>

                    <Row className='mt-10' gutter={[20, 20]}>
                        <Col sm={12} md={6}>
                            <div className='item-card h-full'>
                                <div className='flex gap-4 items-center'>
                                    <div className='icon-holder'>
                                        <CalculatorOutlined/>
                                    </div>
                                    <h5>
                                        Get an Online Estimate
                                    </h5>
                                </div>
                                <div className='h-full'>
                                    <p>
                                        Use our online estimator to
                                        pick your curtain style and
                                        check the prices
                                    </p>
                                </div>
                                <div className='mt-5 flex justify-center items-center'>
                                    <button onClick={onGetEstimate} className='primary-button'>Get an Estimate</button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className='item-card h-full'>
                                <div className='flex gap-4 items-center'>
                                    <div className='icon-holder'>
                                        <CalendarOutlined/>
                                    </div>
                                    <h5>
                                        Book a Free Visit
                                    </h5>
                                </div>
                                <div className='h-full'>
                                    <p>
                                        Our expert will measure and
                                        assist you in selecting your
                                        preferred style and fabrics.
                                    </p>
                                </div>
                                <div className='mt-5 flex justify-center items-center'>
                                    <button onClick={onBookVisit} className='primary-button'>Book a Free Visit</button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className='item-card h-full'>
                            <div className='flex gap-4 items-center'>
                                    <div className='icon-holder'>
                                        <CreditCardOutlined/>
                                    </div>
                                    <h5>
                                        Confirm & Pay
                                    </h5>
                                </div>
                                <div className='h-full'>
                                    <p>
                                        You will receive the
                                        quotation within 2 hours.
                                        Choose to pay upfront or in
                                        4 monthly installments
                                    </p>
                                </div>
                                <div className='mt-5 flex justify-center items-center'>
                                    <button onClick={onBookVisit} className='primary-button'>Book a Free Visit</button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className='item-card h-full'>
                            <div className='flex items-center gap-4'>
                                    <div className='icon-holder'>
                                        <TruckOutlined/>
                                    </div>
                                    <h5>
                                        Delivery & installation
                                    </h5>
                                </div>
                                <div className='h-full'>
                                    <p>
                                        We guarantee fast delivery !
                                        Get your dream curtains
                                        installed within 4 days.
                                    </p>
                                </div>
                                <div className='mt-5 flex justify-center items-center'>
                                    <button onClick={onBookVisit} className='primary-button'>Book a Free Visit</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div id='choose' className='section tabs-section custom-container mt-16'>
                <h2 className='text-center'>How to choose</h2>
                    <p className='mb-5 mt-5 text-center'>
                        Select your curtain or blind type,<br className='mobile-only' /> style, fabric, and accessories
                    </p>

                    <Tabs
                        defaultActiveKey="1"
                        centered
                        items={[
                            {
                                label: 'Curtains',
                                key: '1',
                                children: <div className='curtains-section-wrapper'>
                                    <h2>Curtain Types</h2>

                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        pagination={true}
                                        spaceBetween={50}
                                        slidesPerView={3}
                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={WhiteSheerImage} alt='Sheer'/>
                                                </div>
                                                <h3>Sheer</h3>
                                                <p>
                                                    Add a touch of elegance while diffusing natural light and
                                                    maintaining privacy.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                            <div className='image-holder'>
                                                    <img src={BlackoutFabricImage} alt='Blackout'/>
                                                </div>
                                                <h3>Blackout</h3>
                                                <p>
                                                    Perfect for blocking out sunlight and ensuring a restful sleep
                                                    environment.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={BlackoutAndSheerImage} alt='Blackout & Sheer'/>
                                                </div>
                                                <h3>Blackout & Sheer</h3>
                                                <p>
                                                    Combine the benefits of blackout with the elegance of sheer
                                                    curtains, offering versatility in light control and privacy.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                    <h2>Curtain Styles</h2>
                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        spaceBetween={50}
                                        slidesPerView={3}
                                        pagination={true}
                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={AmericanPleatImage} alt='American Pleat'/>
                                                </div>
                                                <h3>American Pleat</h3>
                                                <p>
                                                    Classic elegance with neatly folded pleats for a timeless window
                                                    look.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={WaveStyleImage} alt='Wavy'/>
                                                </div>
                                                <h3>Wavy Style</h3>
                                                <p>
                                                    Modern touch with gently flowing waves for added style and
                                                    dimension.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={EyeletImage} alt='Eyelet'/>
                                                </div>
                                                <h3>Eyelet Curtains</h3>
                                                <p>
                                                    Easy elegance with metal eyelets for a simple, contemporary
                                                    appearance.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                    <h2>Curtain Fabrics</h2>
                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        spaceBetween={50}
                                        slidesPerView={3}
                                        pagination={true}
                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={SatinImage} alt='Satin'/>
                                                </div>
                                                <h3>Satin</h3>
                                                <p>
                                                    Luxurious sheen and smooth texture for a sophisticated ambiance.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={LinenImage} alt='Linen'/>
                                                </div>
                                                <h3>Linen</h3>
                                                <p>
                                                    Natural and breathable fabric with a relaxed, casual vibe.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={JaquardImage} alt='Jacquard'/>
                                                </div>
                                                <h3>Jacquard</h3>
                                                <p>
                                                    Detailed woven patterns for an elegant and decorative touch.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                    <h2>Curtains Accessories</h2>
                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        spaceBetween={50}
                                        slidesPerView={3}
                                        pagination={true}
                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={PelmetsImage} alt='Pelmets'/>
                                                </div>
                                                <h3>Pelmet Box</h3>
                                                <p>
                                                    Decorative covering for curtain fixtures, adding a finishing
                                                    touch to your window treatment.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={TieBacksImage} alt='Tie Backs'/>
                                                </div>
                                                <h3>Tie backs</h3>
                                                <p>
                                                    Fabric or rope used to hold curtains away from the window,
                                                    enhancing their appearance and allowing light to enter.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={MotorizedImage} alt='Motorized'/>
                                                </div>
                                                <h3>Motorized</h3>
                                                <p>
                                                    Modern feature allowing remote or automated control of curtain
                                                    movement for convenience and efficiency.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={StringsImage} alt='Strings'/>
                                                </div>
                                                <h3>Strings</h3>
                                                <p>
                                                    Thin cords used to operate curtain mechanisms, providing manual
                                                    control for opening and closing.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={MovingSticksImage} alt='Moving Sticks'/>
                                                </div>
                                                <h3>Moving Sticks</h3>
                                                <p>
                                                    Rods attached to curtains for easy manual manipulation,
                                                    especially for tall or hard-to-reach windows.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            },
                            {
                                label: 'Blinds',
                                key: '2',
                                children: <div className='curtains-section-wrapper'>
                                    <h2>Blinds Types</h2>

                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        spaceBetween={50}
                                        slidesPerView={2}
                                        pagination={true}
                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 2,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={RomanBlindsImage} alt='Roman'/>
                                                </div>
                                                <h3>Roman</h3>
                                                <p>
                                                    Timelessly stylish, these blinds fold neatly and are ideal for
                                                    adding a refined look to any room.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={RollerBlindsImage} alt='Roller'/>
                                                </div>
                                                <h3>Roller</h3>
                                                <p>
                                                    Sleek and functional, roller blinds offer effortless light
                                                    control and privacy with a modern aesthetic.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                    <h2>Blinds Fabric</h2>
                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        spaceBetween={50}
                                        slidesPerView={3}
                                        pagination={true}
                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={BlackoutRollerImage} alt='Blackout'/>
                                                </div>
                                                <h3>Blackout</h3>
                                                <p>
                                                    Blocks out light completely, providing maximum privacy and
                                                    darkness.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={TranslucentRollerImage} alt={'Translucent'}/>
                                                </div>
                                                <h3>Translucent</h3>
                                                <p>
                                                    Allows diffused light to enter while maintaining privacy,
                                                    offering a soft, gentle glow in the room.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={SunscreenRollerImage} alt='Sheer'/>
                                                </div>
                                                <h3>Sunscreen</h3>
                                                <p>
                                                    Filters sunlight to reduce glare and heat while preserving
                                                    outward visibility, creating a comfortable and inviting
                                                    atmosphere.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                    <h2>Blinds Accessories</h2>
                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        spaceBetween={50}
                                        slidesPerView={2}
                                        pagination={true}
                                        breakpoints={{
                                            300: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 2,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={SideChannelRollerImage} alt='Side Channels'/>
                                                </div>
                                                <h3>Side channels</h3>
                                                <p>
                                                    Vertical channels installed on the sides of blinds, enhancing
                                                    light blockage, privacy, and insulation by reducing gaps between
                                                    the blind and the window frame.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='curtains-card'>
                                                <div className='image-holder'>
                                                    <img src={CassetteRollerImage} alt='Cassette'/>
                                                </div>
                                                <h3>Cassettes</h3>
                                                <p>
                                                    Sleek enclosures at the top of blinds, providing a neat and tidy
                                                    appearance while concealing the operating mechanism.
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            }
                        ]}
                    />
                </div>

                <div id='faq' className='section custom-container faq-section mt-16'>
                    <h2 className='text-center'>Frequently Asked Questions</h2>

                    <Collapse expandIcon={() => <PlusOutlined style={{ fontSize: '20px', marginTop: '8px', color: '#CC8950FF' }} />} className='mt-5' defaultActiveKey={['1']}>
                        <Collapse.Panel header="How long does it take to schedule a visit ?" key="1">
                            <p>
                                It only takes 24 hours ! You can visit www.curtainz.ae & book
                                the next available spot.
                            </p>
                        </Collapse.Panel>
                        <Collapse.Panel header="How Much Is The Cost Of The Visit ?" key="2">
                            <p>
                                The visit is FREE of charge. After our specialists take the
                                necessary measurements, you'll get a quotation within 2 hours.
                                After that you can confirm the order & pay online.
                            </p>
                        </Collapse.Panel>
                        <Collapse.Panel header="Can I Pay In Installments ?" key="3">
                            <p>

                                You can pay upfront or split your purchases into 4 interest-free
                                payments !
                            </p>
                        </Collapse.Panel>
                        <Collapse.Panel header="When Do I Receive The Curtains ?" key="4">
                            <p>
                                Once your order is confirmed, you’ll get your curtains delivered
                                & installed within 3-5 days !
                            </p>
                        </Collapse.Panel>
                        <Collapse.Panel header="Can I Get A Cost Estimate ?" key="5">
                            <p>
                                Definitely ! You can visit www.curtainz.ae & get an instant cost
                                estimate.
                            </p>
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default HomePage;
