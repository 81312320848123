import {LogoMedium, LogoSmall} from "../../assets/assets";
import {Link, useNavigate} from "react-router-dom";
import './navbar.scss';
import MenuIcon from "../icons/MenuIcon";
import {useState} from "react";
import {Button} from "antd";
import {CloseOutlined} from "@ant-design/icons";

const Navbar = ({ ignoreSpacer }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const openMenu = () => {
        setMenuOpen(true);
    }

    const onBookVisit = () => {
        setMenuOpen(false);
        navigate('/book');
    }

    const onEstimate = () => {
        setMenuOpen(false);;
        navigate('/estimate');
    }

    const scrollToTop = () => {
        setTimeout(() => {
            window.document.body.scrollTop = 0;
        }, 200);
        // Close Menu
        setMenuOpen(false);
    }

    const onMenuClick = () => {
        setMenuOpen(false);
    }

    return (
        <>
            {
                !ignoreSpacer && (
                    <div className='navbar-illusion'></div>
                )
            }
            <div className='navbar-root'>
                <div className='navbar-container'>
                    <div className='w-full'>
                        <div className='flex justify-between items-center'>
                            <div className='flex gap-6 items-center'>
                                <div className='logo-content'>
                                    <Link to='/'>
                                        <img width={60} height={60} src={LogoSmall} alt='Curtainz Logo'/>
                                    </Link>
                                </div>
                                <div className='nav-items desktop-only'>
                                    <ul>
                                        <li>
                                            <Link onClick={onMenuClick} to='/?section=choose'>How To Choose</Link>
                                        </li>
                                        <li>
                                            <Link onClick={scrollToTop} to='/about'>About Us</Link>
                                        </li>
                                        <li>
                                            <Link onClick={onMenuClick} to='/?section=faq'>Help</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex gap-4 desktop-only'>
                                <button onClick={onEstimate} className='outlined-button'>Get an Estimate</button>
                                <button onClick={onBookVisit} className='primary-button'>Book a Free Visit</button>
                            </div>
                            <div className='mobile-only'>
                                <div className='cursor-pointer flex items-center' onClick={openMenu}>
                                    <MenuIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                menuOpen && (
                    <div className='mobile-menu'>
                        <div className='flex justify-between'>
                            <Link to='/'>
                                <img className='mb-10' src={LogoMedium} style={{ width: '150px' }} />
                            </Link>

                            <Button type='primary' shape='circle' icon={<CloseOutlined />} onClick={() => setMenuOpen(false)} />
                        </div>
                        <ul>
                            <li>
                                <Link onClick={onMenuClick} onClick={scrollToTop} to='/'>Home</Link>
                            </li>
                            <li>
                                <Link onClick={onMenuClick} to='/?section=choose'>How To Choose</Link>
                            </li>
                            <li>
                                <Link onClick={scrollToTop} to='/about'>About Us</Link>
                            </li>
                            <li>
                                <Link onClick={onMenuClick} to='/?section=faq'>Help</Link>
                            </li>
                            <li>
                                <button onClick={onEstimate} className='outlined-button'>Get an Estimate</button>
                            </li>
                            <li>
                                <button onClick={onBookVisit} className='primary-button'>Book a Free Visit</button>
                            </li>
                        </ul>
                    </div>
                )
            }
        </>
    )
}

export default Navbar;
