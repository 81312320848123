const MenuIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         width="25" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="25"
         preserveAspectRatio="xMidYMid meet" version="1.0">
        <defs>
            <clipPath id="4b8fb48f6a">
                <path
                    d="M 10.804688 71.324219 L 364.054688 71.324219 L 364.054688 102 L 10.804688 102 Z M 10.804688 71.324219 "
                    clipRule="nonzero"/>
            </clipPath>
            <clipPath id="e5bfcaf59a">
                <path
                    d="M 10.804688 172 L 364.054688 172 L 364.054688 203 L 10.804688 203 Z M 10.804688 172 "
                    clipRule="nonzero"/>
            </clipPath>
            <clipPath id="5083927865">
                <path
                    d="M 10.804688 273 L 364.054688 273 L 364.054688 303.824219 L 10.804688 303.824219 Z M 10.804688 273 "
                    clipRule="nonzero"/>
            </clipPath>
        </defs>
        <g clipPath="url(#4b8fb48f6a)">
            <path fill="#cc8950"
                  d="M 364.175781 86.539062 C 364.175781 94.902344 357.394531 101.679688 349.03125 101.679688 L 25.957031 101.679688 C 17.59375 101.679688 10.8125 94.902344 10.8125 86.539062 C 10.8125 78.175781 17.59375 71.394531 25.957031 71.394531 L 349.03125 71.394531 C 357.394531 71.394531 364.175781 78.175781 364.175781 86.539062 Z M 364.175781 86.539062 "
                  fillOpacity="1" fillRule="evenodd"/>
        </g>
        <g clipPath="url(#e5bfcaf59a)">
            <path fill="#cc8950"
                  d="M 364.175781 187.5 C 364.175781 195.863281 357.394531 202.644531 349.03125 202.644531 L 25.957031 202.644531 C 17.59375 202.644531 10.8125 195.863281 10.8125 187.5 C 10.8125 179.136719 17.59375 172.355469 25.957031 172.355469 L 349.03125 172.355469 C 357.394531 172.355469 364.175781 179.136719 364.175781 187.5 Z M 364.175781 187.5 "
                  fillOpacity="1" fillRule="evenodd"/>
        </g>
        <g clipPath="url(#5083927865)">
            <path fill="#cc8950"
                  d="M 364.175781 288.460938 C 364.175781 296.824219 357.394531 303.605469 349.03125 303.605469 L 25.957031 303.605469 C 17.59375 303.605469 10.8125 296.824219 10.8125 288.460938 C 10.8125 280.097656 17.59375 273.316406 25.957031 273.316406 L 349.03125 273.316406 C 357.394531 273.316406 364.175781 280.097656 364.175781 288.460938 Z M 364.175781 288.460938 "
                  fillOpacity="1" fillRule="evenodd"/>
        </g>
    </svg>
)

export default MenuIcon;
