import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import {ConfigProvider} from "antd";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import AboutPage from "./pages/about/AboutPage";
import BookVisitPage from "./pages/book/bookVisitPage";
import GetEstimatePage from "./pages/estimate/GetEstimatePage";
import {WhatsAppLogo} from "./assets/assets";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsConditions from "./pages/terms-conditions/TermsConditions";
function App() {
  return (
      <ConfigProvider theme={{ token: { colorPrimary: '#78383C', colorPrimaryBgHover: '#CC8950' } }}>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/book" element={<BookVisitPage />} />
                  <Route path="/estimate" element={<GetEstimatePage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-conditions" element={<TermsConditions />} />
              </Routes>
          </BrowserRouter>
          <a href='https://wa.link/kfi0iq' target='_blank' className='whats-app-button-wrapper'>
              <img src={WhatsAppLogo} alt='Whatsapp' />
          </a>
      </ConfigProvider>
  );
}

export default App;
