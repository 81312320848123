import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import {AboutUsImage, AboutUsLandingImage, MariaImage, MichaelImage} from "../../assets/assets";
import './about.scss';
import {Col, Row} from "antd";
import {useEffect} from "react";

const AboutPage = () => {

    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.defer = true;
        script.setAttribute('data-use-service-core', '');

        // Append script to the document head
        document.head.appendChild(script);

        // Cleanup function to remove script when component unmounts
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // Make Sure We Are At The Top Of The Page
        window.document.body.scrollTop = 0;
    }, []);

    return (
        <>
            <Navbar ignoreSpacer={true} />

            <main className='about-wrapper'>
                <div className='top-section'>
                    <img src={AboutUsImage} alt='About Us' />
                </div>
                <div className='title-section'>
                    <div className='card-content w-full custom-container'>
                        <h1>Our Mission</h1>
                        <p>
                            Enhancing Dubai's curtain shopping
                            experience<br className='desktop-only' /> by ensuring every step,
                            from selection to installation, is
                            hassle-free.
                        </p>
                    </div>
                </div>

                <div className='story-section custom-container mt-10 mb-10'>
                    <h2>Our Story</h2>

                    <p className='mb-5'>
                        When we moved to Dubai in 2019, we found
                        it really difficult to get <b>custom curtains</b> for
                        our new home:<br/>Unclear prices, Unreliable
                        suppliers and complicated process
                    </p>
                    <p className='mb-2'>
                        If we were struggling, we figured others
                        probably were too. That's why we started <b className='special'>Curtainz</b>.
                    </p>
                    <p className='mb-2'>
                        Our aim is straightforward: to make curtain
                        shopping stress-free for all.
                    </p>
                    <p>
                        At <b className='special'>Curtainz</b>, we're not just selling curtains;
                        we're solving a common problem.
                    </p>
                </div>

                <div className='team-section custom-container'>
                    <h2>Our Leaders</h2>

                    <Row gutter={[20, 20]}>
                        <Col className='team-cards' xs={12}>
                            <div className='team-card'>
                                <img src={MichaelImage} alt='Michael' />
                                <div className='team-text'>
                                    <h3>Michael</h3>
                                    <p>Founder</p>
                                </div>
                            </div>

                        </Col>
                        <Col className='team-cards' xs={12}>
                            <div className='team-card'>
                                <img src={MariaImage} alt='Maria' />
                                <div className='team-text'>
                                    <h3>Marita</h3>
                                    <p>Executive Director</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='reviews-section custom-container mt-10'>
                    <h2>Our Reviews</h2>

                    <div className="elfsight-app-b3511470-ab8c-46b4-9d28-2a83d467d146" data-elfsight-app-lazy></div>
                </div>
            </main>

            <Footer/>
        </>
    )
}

export default AboutPage;