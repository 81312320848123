import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import '../privacy-policy/privacy-policy.scss'
import {useEffect} from "react";

export const TermsConditions = () => {
    useEffect(() => {
        // Make Sure We Are At The Top Of The Page
        window.document.body.scrollTop = 0;
    }, []);

    return (
        <>
            <Navbar />

            <main className='custom-container root-privacy'>
                <h1>Terms of Use</h1>

                <p>
                    Welcome to Curtainz. By accessing our website and purchasing our products, you agree to comply with
                    our Terms of Service. Please read these terms carefully before using our site. We reserve the right
                    to update these terms, and your continued use of our website constitutes acceptance of any changes.
                </p>

                <h2>Section 1 – Online Store Terms</h2>

                <p>
                    By agreeing to these Terms of Service, you represent that you are of legal age in your state or
                    province of residence or have given us consent to allow any of your minor dependents to use this
                    site. You may not use our products for any illegal or unauthorized purpose and must not violate any
                    laws in your jurisdiction, including copyright laws. Additionally, you must not transmit any worms,
                    viruses, or any code of a destructive nature. Violation of any of these Terms of Service will result
                    in an immediate termination of our services.
                </p>

                <h2>Section 2 – General Conditions</h2>

                <p>
                    We reserve the right to refuse service to anyone for any reason at any time. You agree not to
                    reproduce, duplicate, copy, sell, resell, or exploit any portion of our services without our express
                    written permission. The headings used in these Terms of Service are included for convenience only
                    and will not limit or affect the terms and conditions contained herein.
                </p>

                <h2>Section 3 – Accuracy, Completeness, and Timeliness of Information</h2>

                <p>
                    While we strive to provide accurate and up-to-date information on our website, we do not guarantee
                    the accuracy, completeness, or timeliness of all information. The material on our site is provided
                    for general information purposes only and should not be relied upon or used as the sole basis for
                    making decisions without consulting primary, more accurate, more complete, or more timely sources of
                    information. Any reliance on the material on our site is at your own risk.
                </p>

                <h2>Section 4 – Modifications to the Service and Prices</h2>

                <p>
                    Prices for our products are subject to change without notice. We reserve the right to modify or
                    discontinue our services (or any part thereof) without notice at any time. We shall not be liable to
                    you or any third party for any modification, price change, suspension, or discontinuance of our
                    services. Full payment must be settled in advance before we start providing services.
                </p>


                <h2>Section 5 – Products or Services</h2>

                <p>
                    Certain products or services may be available exclusively online through our website. These products
                    or services may have limited quantities and are subject to return or exchange only according to our
                    Return Policy. We have made every effort to display the colors and images of our products on our
                    store as accurately as possible, but we cannot guarantee that your computer monitor's display will
                    be accurate.
                </p>

                <h2>Section 6 – Delivery, Return, and Cancellation Policy</h2>

                <p>
                    All sales are final, and no refund or exchange will be provided once the order is placed and paid by
                    the client. Curtainz does not offer refunds or exchanges under any circumstances. In case of damage
                    or defects, Curtainz team will inspect the item at the customer's location. If the damage is not
                    attributed to customer actions, Curtainz will rectify it under warranty. Otherwise, the customer
                    will be solely responsible for any associated costs and remedies.
                </p>

                <h2>Section 7 – Accuracy of Billing and Account Information</h2>

                <p>
                    We reserve the right to refuse any order you place with us and may limit or cancel quantities
                    purchased per person, per household, or per order at our discretion. We may attempt to notify you of
                    any changes or cancellations by contacting the provided email or billing address/phone number. You
                    agree to provide accurate account information and promptly update it as necessary for completing
                    transactions.
                </p>

                <h2>Section 8 – Errors, Delays, and Third-Party Installation</h2>

                <p>
                    While we strive for accuracy and timely service, errors, delays, or omissions may occur. We reserve
                    the right to correct any inaccuracies and update information without prior notice. Please note that
                    we cannot guarantee delivery and installation times due to various factors beyond our control, such
                    as logistical challenges or unforeseen circumstances. Additionally, we may occasionally engage a
                    third-party installation team to facilitate the installation process. Rest assured, these teams are
                    carefully vetted, and we maintain quality standards to ensure a satisfactory experience for our
                    customers.
                </p>

                <h2>Section 9 – No Warranty</h2>

                <p>
                    Please note that our products are sold without any warranty, express or implied. While we take
                    utmost care in crafting and delivering our products to meet your expectations, we do not offer any
                    warranty for defects or damages. This includes but is not limited to defects in materials,
                    workmanship, or performance. We recommend reviewing the product upon receipt and contacting us
                    immediately if any issues arise.
                </p>

                <h2>Section 10 – Governing Law</h2>

                <p>
                    These Terms of Service and any separate agreements whereby we provide you services shall be governed by and construed in accordance with the laws of Dubai, United Arab Emirates. Curtainz will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE.
                </p>

                <h2>Section 11 – Changes to Terms of Service</h2>

                <p>
                    You can review the most current version of the Terms of Service at any time on this page. We reserve the right, at our sole discretion, to update, change, or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                </p>

                <h2>Section 12 – Contact Information</h2>

                <p>
                    Questions about the Terms of Service should be sent to us at <a href='mailto:info@curtainz.ae'>info@curtainz.ae</a>.
                </p>
            </main>

            <Footer/>
        </>
    )
}

export default TermsConditions;