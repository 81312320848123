import './footer.scss';
import {Col, Row} from "antd";
import {LogoMedium} from "../../assets/assets";
import {FacebookOutlined, InstagramOutlined, MailOutlined, PhoneOutlined, PushpinOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer className='footer-root mt-16'>
            <div className='custom-container'>
                <Row gutter={[15, 40]}>
                    <Col className='desktop-only' xs={24} sm={24} md={8}>
                        <div className='logo-holder'>
                            <img width={250} src={LogoMedium} alt='Medium Logo'/>
                        </div>
                        <p className='mt-3'>
                            Copyright 2024<br/>
                            Curtainz. All rights reserved
                        </p>
                        <h4>Follow Us</h4>
                        <div className='flex gap-3 follow-us-section'>
                            <InstagramOutlined />
                            <FacebookOutlined />
                        </div>
                    </Col>
                    <Col className='mobile-only' xs={24} sm={24} md={8}>
                        <div className='flex items-start justify-between'>
                            <div className='logo-holder'>
                                <img width={250} src={LogoMedium} alt='Medium Logo'/>
                            </div>
                            <div className='flex gap-3 follow-us-section'>
                                <a href='https://www.instagram.com/curtainz.ae/' target='_blank'>
                                    <InstagramOutlined/>
                                </a>
                                <a href='https://www.facebook.com/Curtainz.ae' target='_blank'>
                                    <FacebookOutlined/>
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col className='links-content' xs={24} sm={24} md={8}>
                        <Row gutter={[15, 15]}>
                            <Col xs={12}>
                                <Link to={'/'}>Home</Link>
                            </Col>
                            <Col xs={12}>
                                <Link to={'/about'}>About Us</Link>
                            </Col>
                            <Col xs={12}>
                                <Link to={'/estimate'}>Get Estimate</Link>
                            </Col>
                            <Col xs={12}>
                                <Link to={'/?section=choose'}>How to choose</Link>
                            </Col>
                            <Col xs={12}>
                                <Link to={'/privacy-policy'}>Privacy Policy</Link>
                            </Col>
                            <Col xs={12}>
                                <Link to={'/?section=faq'}>FAQs</Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <div className='address-section h-full'>
                            <div className='content'>
                                <div className='flex gap-3'>
                                    <PushpinOutlined className='icon' />
                                    <p>Dubai, UAE</p>
                                </div>
                                <div className='flex gap-3'>
                                    <PhoneOutlined className='icon' />
                                    <a href='tel:+971 52 457 6774'>+971 52 457 6774</a>
                                </div>
                                <div className='flex gap-3'>
                                    <MailOutlined className='icon' />
                                    <a href='mailto:info@curtainz.ae'>info@curtainz.ae</a>
                                </div>
                            </div>

                            <p className='mt-10'>Developed By Klutch. Powered By <a href='https://jaysoftworks.com'><b>Jay Softworks</b></a>
                            </p>
                        </div>
                    </Col>
                    <Col className='mobile-only' sm={24} md={8}>
                        <p className='mb-5'>
                            Copyright 2024<br/>
                            Curtainz. All rights reserved
                        </p>
                    </Col>
                </Row>
            </div>
        </footer>
    )
}

export default Footer;
