import {Col, Input, Row} from "antd";
import {BlackoutAndSheerImage, BlackoutFabricImage, RollerBlindsImage, WhiteSheerImage} from "../../assets/assets";
import classNames from "classnames";
import {useEffect, useState} from "react";
import './get-estimate.scss';
import {useNavigate} from "react-router-dom";

const GetEstimate = () => {
    const [estimationType, setEstimationType] = useState('white-sheer');
    const [width, setWidth] = useState(200);
    const [height, setHeight] = useState(300);
    const [estimate, setEstimate] = useState({ upfront: 0, estimate: 0 });

    const navigate = useNavigate();

    useEffect(() => {
        let cost, estimateResult, upfrontResult;

        switch (estimationType) {
            case "white-sheer":
                cost = width * 0.85 + ((width * height) / 10000) * 16;
                upfrontResult = cost * 1.75 * 0.85;
                estimateResult = upfrontResult / 4;
                break;
            case "blackout-fabric":
                cost = width * 0.85 + ((width * height) / 10000) * 40;
                upfrontResult = cost * 1.6 * 0.85;
                estimateResult = upfrontResult / 4;
                break;
            case "blackout-and-sheer":
                cost = width * 1.3 + ((width * height) / 10000) * 56;
                upfrontResult = cost * 1.6 * 0.85;
                estimateResult = upfrontResult / 4;
                break;
            case "roller-blinds":
                cost = width * 0 + ((width * height) / 10000) * 80;
                upfrontResult = cost * 1.6 * 0.85;
                estimateResult = upfrontResult / 4;
                break;
            default:
                return;
        }

        setEstimate({
            upfront: upfrontResult >= 1000 ? Math.floor(upfrontResult).toLocaleString() : Math.floor(upfrontResult),
            estimate: estimateResult >= 1000 ? Math.floor(estimateResult).toLocaleString() : Math.floor(estimateResult),
        });
    }, [estimationType, width, height]);

    const onEstimationTypeChange = (type) => {
        setEstimationType(type);
    }

    const onBookVisit = () => {
        navigate('/book');
    }

    return (
        <div className='custom-container estimate-section-wrapper'>
            <h2 className='text-center' style={{ marginBottom: 0 }}>Get an Estimate</h2>
            <Row className='mt-14' gutter={[50, 20]}>
                <Col sm={24} md={12}>
                    <div className='flex flex-col gap-3 h-full justify-center items-center'>
                        <img style={{borderRadius: '15px'}} src={
                            estimationType === 'white-sheer' ? WhiteSheerImage :
                                estimationType === 'blackout-fabric' ? BlackoutFabricImage :
                                    estimationType === 'blackout-and-sheer' ? BlackoutAndSheerImage :
                                        RollerBlindsImage
                        } alt='Preview'/>
                    </div>
                </Col>
                <Col sm={24} md={12}>
                    <div className='estimate-content'>
                        <div className='flex justify-between gap-3'>
                            <div className='flex gap-4 items-center mt-3'>
                                <div className='input-element'>
                                    <label>Width</label>
                                    <Input size='large' addonAfter='cms' className='mt-2 w-full'
                                           value={width} onChange={(e) => {
                                        setWidth(e.target.value);
                                    }}/>
                                </div>
                                <span style={{marginTop: '25px'}}>X</span>
                                <div className='input-element'>
                                    <label>Height</label>
                                    <Input size='large' addonAfter='cms' className='mt-2 w-full'
                                           value={height} onChange={(e) => {
                                        setHeight(e.target.value);
                                    }}/>
                                </div>
                            </div>

                            <div className='presets-section desktop-only'>
                                <label>Presets</label>
                                <div className='flex flex-col gap-1'>
                                    <div className='flex justify-center gap-3'>
                                        <button onClick={() => {
                                            setWidth(200);
                                            setHeight(400);
                                        }}>200x400</button>
                                        <button onClick={() => {
                                            setWidth(300);
                                            setHeight(500);
                                        }}>500x700</button>
                                    </div>
                                    <div className='flex justify-center gap-3'>
                                        <button onClick={() => {
                                            setWidth(450);
                                            setHeight(500);
                                        }}>450x500</button>
                                        <button onClick={() => {
                                            setWidth(650);
                                            setHeight(800);
                                        }}>650x800</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className='mt-5'>Style</h2>
                    <div className='flex justify-evenly gap-3'>
                        <div onClick={
                            () => onEstimationTypeChange('white-sheer')
                        } className={classNames('style-card w-full', {
                            'selected': estimationType === 'white-sheer'
                        })}>
                            <div className='image-holder'>
                                <img src={WhiteSheerImage} alt='Sheer'/>
                            </div>
                            <p>White<br/>Sheer</p>
                        </div>
                        <div onClick={
                            () => onEstimationTypeChange('blackout-fabric')
                        } className={classNames('style-card w-full', {
                            'selected': estimationType === 'blackout-fabric'
                        })}>
                            <div className='image-holder'>
                                <img src={BlackoutFabricImage} alt='Blackout'/>
                            </div>
                            <p>Blackout<br/>Fabric</p>
                        </div>
                        <div onClick={
                            () => onEstimationTypeChange('blackout-and-sheer')
                        } className={classNames('style-card w-full', {
                            'selected': estimationType === 'blackout-and-sheer'
                        })}>
                            <div className='image-holder'>
                                <img src={BlackoutAndSheerImage} alt='Blackout & Sheer'/>
                            </div>
                            <p>Blackout<br/>& Sheer</p>
                        </div>
                        <div onClick={
                            () => onEstimationTypeChange('roller-blinds')
                        } className={classNames('style-card w-full', {
                            'selected': estimationType === 'roller-blinds'
                        })}>
                            <div className='image-holder'>
                                <img src={RollerBlindsImage} alt='Bed'/>
                            </div>
                            <p>Roller<br/>Blinds</p>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <h3>AED {estimate.estimate}/month <span>(for 4 months)</span></h3>
                        <h4>Or <b>AED {estimate.upfront}</b> upfront</h4>

                        <p className='note mt-4'>
                            *The price shown is for the
                            standard package. Prices for
                            upgraded options, such as
                            premium sheer or wavy
                            curtains, as well as
                            automation, may differ. To
                            receive a personalized
                            quotation, schedule a visit with
                            us today!
                        </p>
                    </div>

                    <button onClick={onBookVisit} className='primary-button large w-full mt-5'>Book a Free Visit
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export default GetEstimate;