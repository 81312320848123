import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Tag} from "antd";
import './book.scss';
import {useEffect, useState} from "react";
import {CheckCircleOutlined} from "@ant-design/icons";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";

const BookVisitPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [bookingConfirmedOpen, setBookingConfirmedOpen] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        // Make Sure We Are At The Top Of The Page
        window.document.body.scrollTop = 0;
    }, []);

    const meetingSlots = [
        '09:00-10:30',
        '10:00-11:30',
        '11:00-12:30',
        '12:00-13:30',
        '13:00-14:30',
        '14:00-15:30',
        '15:00-16:30',
        '16:00-17:30',
        '17:00-18:30'
    ];

    const onConfirmVisit = async (values) => {
        if (!selectedSlot) {
            messageApi.open({
                type: 'warning',
                content: 'Please select a time slot',
            });
            return;
        }
        const message = `
            --> Book A Visit
            🙍🏼‍ : ${values.firstName} ${values.lastName}
            📆 : ${values.date.format('DD/MM/YYYY')}
            ⏰ : ${selectedSlot}
            📍: ${values.address} - ${values.flatNo}
            📞: ${values.phone}
            📧: ${values.email}
            🏠: ${values.numOfWindows} Windows
            📝: ${values.comment || 'No Comment'}
        `;
        setIsLoading(true);
        try {
            // Post Request
            await fetch('https://api.jaysoftworks.com/v1/mawarid/public/contact-us/raw', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    key: 'mw-bkbzd536x2lzmml2ks',
                    message,
                })
            });
            setBookingConfirmedOpen(true);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: 'Failed to book a visit, please try again later',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Navbar/>

            <main className='custom-container book-root'>

                <h1>Book a Free Visit</h1>

                <Form onFinish={onConfirmVisit} layout='vertical'>
                    <Row gutter={[10, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item rules={[
                                {
                                    validator: (_, value) => {
                                        if (new Date().getHours() >= 16) {
                                            const tmrw = new Date().setDate(new Date().getDate() + 1);
                                            if (value && value.isAfter(new Date(tmrw), 'day')) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('You cannot select today or tomorrow');
                                            }
                                        } else {
                                            // Do not allow to select today
                                            if (value && value.isAfter(new Date(), 'day')) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('You cannot select today');
                                            }
                                        }
                                    }
                                },
                                {
                                    required: true,
                                    message: '',
                                }
                            ]} name='date' label='Select Date'>
                                <DatePicker size='large' style={{width: '100%'}} placeholder='Date Suitable for Visit'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <label style={{fontSize: '1.5rem', marginBottom: '1px', display: 'block'}}>* Select Time
                                Slot</label>
                            {
                                meetingSlots.map((s, index) => {
                                    return (
                                        <div onClick={() => {
                                            setSelectedSlot(s);
                                        }} className={classNames('tag-element', {
                                            'selected': selectedSlot === s,
                                        })} key={index} style={{fontSize: '1.5rem', marginRight: '1rem'}}>
                                            {s}
                                        </div>
                                    )
                                })
                            }
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Please enter your email'
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email'
                                }
                            ]} className='mt-2' name='email' label='Email'>
                                <Input size='large'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Please enter your first name'
                                }
                            ]} name='firstName' label='First Name'>
                                <Input size='large'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Please enter your last name'
                                }
                            ]} name='lastName' label='Last Name'>
                                <Input size='large'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Please enter your phone number'
                                }
                            ]} name='phone' label='Phone No'>
                                <Input prefix='🇦🇪 +971' size='large' placeholder='XXX XXX XXX'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Please enter your address'
                                }
                            ]} name='address' label='Address'>
                                <Input size='large' placeholder='Address In Dubai'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Please enter your flat number'
                                }

                            ]} name='flatNo' label='Flat / Villa number'>
                                <Input size='large' placeholder='Flat No. / Gate No.'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                    message: 'Please select the number of windows'
                                }
                            ]} name='numOfWindows' label='Number Of Windows'>
                                <Select size='large' style={{width: '100%'}}>
                                    <Select.Option value='1'>1</Select.Option>
                                    <Select.Option value='2'>2</Select.Option>
                                    <Select.Option value='3'>3</Select.Option>
                                    <Select.Option value='more'>more</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name='comment' label='Comment (Optional)'>
                                <Input.TextArea rows={4} size='large' placeholder='Notes / Comments / etc.'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Button htmlType='submit' loading={isLoading} block style={{height: '50px'}} type='submit'
                                    className='primary-button large'>Confirm The Visit</Button>
                        </Col>
                    </Row>
                </Form>
            </main>

            <Modal open={bookingConfirmedOpen} footer={<> </>} onCancel={() => {
                setBookingConfirmedOpen(false);
            }}>
                <div className='confirmation-popup'>
                    <CheckCircleOutlined className='icon'/>
                    <h3>Confirmation</h3>
                    <p>
                        Thank you for booking a visit <br className='mobile-only'/>with <b>Curtainz</b>.
                    </p>
                    <p>
                        Our team will be in touch with you shortly to confirm your visit.
                    </p>
                    <button onClick={() => {
                        navigate('/');
                    }} className='primary-button w-full'>Back To Home</button>
                    <button onClick={() => {
                        setBookingConfirmedOpen(false)
                    }} className='outlined-button w-full'>Close</button>
                </div>
            </Modal>

            <Footer/>
        </>
    )
}

export default BookVisitPage;
