import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import './privacy-policy.scss';
import {useEffect} from "react";

export const PrivacyPolicy = () => {
    useEffect(() => {
        // Make Sure We Are At The Top Of The Page
        window.document.body.scrollTop = 0;
    }, []);

    return (
        <>
            <Navbar />

            <main className='custom-container root-privacy'>
                <h1>Privacy Policy</h1>

                <p className='text-center mb-10'>
                    At Curtainz, we are committed to protecting your privacy.<br/>This Privacy Policy outlines how we collect, use, disclose, and protect the personal information we gather from our website users.
                </p>

                <h2>Information We Collect</h2>

                <p>
                    We collect personal information such as names, phone numbers, and email addresses when users schedule a visit to explore our custom-made curtain options.
                </p>

                <h2>How We Use Your Information</h2>

                <p>
                    The personal information we collect is used solely to schedule visits for our clients and to provide them with information about our custom-made curtains.
                </p>

                <h2>Data Storage</h2>

                <p>
                    All collected information is stored securely within our company and is accessible only to authorized personnel.
                </p>

                <h2>Protection of Your Information</h2>

                <p>
                    We take appropriate measures to safeguard the personal information we collect against unauthorized access, disclosure, alteration, or destruction.
                </p>

                <h2>Sharing of Information</h2>

                <p>
                    We do not share, sell, or otherwise disclose personal information collected from our website users to third parties unless required by law.
                </p>

                <h2>User Rights</h2>

                <p>
                    Users have the right to access, update, or delete their personal information stored by us. If you wish to exercise any of these rights, please contact us using the details provided below.
                </p>

                <h2>Changes to this Policy</h2>

                <p>
                    We may update this Privacy Policy from time to time. Any changes will be reflected on this page, and users will be notified of any significant updates.
                </p>

                <h2>Contact Us</h2>

                <p>
                    If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at <a href='mailto:info@curtainz.ae'>info@curtainz.ae</a>
                </p>
            </main>

            <Footer />
        </>
    )
}

export default PrivacyPolicy;